<template>
  <b-container fluid class="vh100 d-flex flex-column justify-content-center align-items-center">
    <Nuxt />
  </b-container>
</template>

<script>
export default {
  name: 'Clean'
}
</script>

<style scoped>
.vh100 {
  height: 100svh;
}
</style>
