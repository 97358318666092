import nav from '~/nav'

export default ({ route, store }) => {
  // Take the last value (latest route child)
  const pageTitle = route.meta.reduce((pageTitle, meta) => meta.displayTitle || pageTitle, '')
  const navItem = nav.find((i) => {
    const pathRgx = new RegExp(`^${i.path}`)
    return route.path.match(pathRgx)
  })
  store.commit('layout/setWindowTitle', pageTitle)
  store.commit('layout/setPageTitle', pageTitle)
  store.commit('layout/setPageIcon', navItem && navItem.icon)
}
