<template>
  <div
    v-resize="onResize"
    class="wrapper"
    :class="{ 'mobile': isMobile, 'tablet': isTablet }"
  >
    <sidebar v-if="!isMobile" />
    <div
      class="content d-flex flex-column"
      :class="{'squized': sidebarState}"
      style="height: 100svh"
    >
      <app-state />
      <nav
        class="navbar navbar-expand-lg navbar-light bg-light"
      >
        <b-navbar-brand>
          <mobile-nav-trigger />
        </b-navbar-brand>
        <b-navbar-brand>
          <font-awesome-icon
            v-if="pageIcon"
            :icon="['fas', pageIcon]"
            class="mr-2"
          />
          <span v-shtml="$t(pageTitle)" />
        </b-navbar-brand>
        <client-only>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto d-inline-flex flex-row">
            <customers-online />
            <!--
            <locale-switcher />
            -->
            <user-header-nav class="user-header-nav" />
            <li class="nav-item d-flex align-items-center">
              <mobile-chat-trigger />
            </li>
          </b-navbar-nav>
        </client-only>
      </nav>
      <Nuxt />
      <global-forms />
      <chat-talk
        v-for="(chat) in chatList"
        :key="`chat-${chat.talkId}`"
        :ref="`chat-${chat.talkId}`"
        v-model="chat.state"
        :talk-id="chat.talkId"
      />
      <mobile-nav />
      <chat-talks />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters } from 'vuex'
import nav from '../nav'
import { randId } from '../mixins/helpers'

export default {
  name: 'Default',
  data () {
    return {
      displayNav: true
    }
  },
  head () {
    return {
      title: 'BO' +
        (this.portId && process.env.NODE_ENV === 'development' ? ` - ${this.portId}` : '') +
        (this.windowTitle !== '' ? ` - ${this.$t(this.windowTitle)}` : ''),
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: `/${this.windowIcon}.ico?v=${randId()}`
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    ...mapFields({
      sidebarState: 'layout.sidebarState',
      windowTitle: 'layout.windowTitle',
      windowIcon: 'layout.windowIcon',
      pageTitle: 'layout.pageTitle',
      pageIcon: 'layout.pageIcon',
      chatList: 'chat.list',
      portId: 'shared.portId'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    isTablet () {
      return this.deviceInfo?.type === 'tablet' || false
    }
  },
  watch: {
    $route (n, o) {
      this.setActivePath(n.path)
    }
  },
  mounted () {
    this.initNav(nav)
    this.setActivePath(this.$route.path)
    this.getDeviceInfo()
    this.getTableLayout()
  },
  methods: {
    ...mapActions({
      initNav: 'nav/init',
      setActivePath: 'nav/setActivePath',
      getDeviceInfo: 'getDeviceInfo',
      getTableLayout: 'getTableLayout'
    }),
    onResize () {
      this.$nextTick(() => {
      //   setTimeout(() => {
      //     if (this.deviceInfo?.type === 'mobile') {
      //       this.displayNav = this.$orientation() === 'portrait'
      //     } else {
      //       this.displayNav = true
      //     }
      //     //  alert('this.displayNav: ' + this.displayNav + ' => ' + this.$orientation())
      //   }, 250)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
