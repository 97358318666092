import { isWindow, isWorker } from './helpers'

class DbLog {
  #isOpened = false
  #db
  #logWaitList = []
  #tabWaitList = []
  #timer = null

  constructor (tabKey) {
    if ((isWindow || isWorker) && !!indexedDB) {
      const r = indexedDB.open('log', 3)
      r.onerror = (event) => {
        console.error("Why didn't you allow my web app to use IndexedDB?!")
      }
      r.onsuccess = async (event) => {
        this.#db = event.target.result
        console.log('Log db opened')
        this.#db.onerror = (event) => {
          console.error(`Database error: ${event.target.errorCode}`, event)
        }
        this.#isOpened = true

        if (this.#tabWaitList.length) {
          for (const row of this.#tabWaitList) {
            await this.#storeTabInfo(row.tab, row.userId, row.title, row.time)
          }
          this.#tabWaitList = []
        }
        if (this.#logWaitList.length) {
          for (const row of this.#logWaitList) {
            await this.#store(row.side, row.tab, row.ts, row.type, row.args)
          }
          this.#logWaitList = []
        }
        if (typeof tabKey !== 'undefined' && !this.#timer) {
          this.#timer = setInterval(() => {
            this.#storeTabInfo(
              tabKey,
              window?.$nuxt?.$store?.$auth?.$state?.user?.userId || '',
              window.document.title,
              new Date()
            )
          }, 5000)
        }
      }
      r.onupgradeneeded = (event) => {
        this.#db = event.target.result
        console.log('Log db onupgradeneeded', this.#db)

        // Create an eventsStore to hold information about our customers. We're
        // going to use "ssn" as our key path because it's guaranteed to be
        // unique - or at least that's what I was told during the kickoff meeting.
        const eventsStore = this.#db.createObjectStore('events', { autoIncrement: true })
        const tabsStore = this.#db.createObjectStore('tabs', { autoIncrement: true })

        eventsStore.createIndex('time', 'time', { unique: false })
        eventsStore.createIndex('side', 'side', { unique: false }) // browser "tab" or "worker"
        eventsStore.createIndex('tab', 'tab', { unique: false })
        eventsStore.createIndex('type', 'type', { unique: false })
        eventsStore.createIndex('name', 'name', { unique: false })
        eventsStore.createIndex('info', 'info', { unique: false })
        eventsStore.createIndex('tab_time', ['tab', 'time'], { unique: false })
        eventsStore.createIndex('side_tab_time', ['side', 'tab', 'time'], { unique: false })

        tabsStore.createIndex('tab', 'tab', { unique: true })
        tabsStore.createIndex('user_id', 'user_id', { unique: false })
        tabsStore.createIndex('title', 'title', { unique: false })
        tabsStore.createIndex('open_at', 'open_at', { unique: false })
        tabsStore.createIndex('last_active_at', 'last_active_at', { unique: false })

        // Use transaction oncomplete to make sure the eventsStore creation is
        // finished before adding data into it.
        eventsStore.transaction.oncomplete = (event) => {
          console.log('Log db events transaction.oncomplete', event)
        }

        // Use transaction oncomplete to make sure the eventsStore creation is
        // finished before adding data into it.
        tabsStore.transaction.oncomplete = (event) => {
          console.log('Log db tabs transaction.oncomplete', event)
        }
      }
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      console.error('IndexedDB not supported')
    }
  }

  #store (side, tab, ts, type, args) {
    if (this.#isOpened) {
      const name = args?.shift() || ''
      if (args?.length === 1) {
        args = args[0]
        if (tab === '' && args?.portId) {
          tab = args.portId
        }
      }
      const data = {
        side,
        tab,
        type,
        time: ts,
        name,
        info: JSON.stringify(args)
      }
      const tr = this.#db.transaction(['events'], 'readwrite')
      const store = tr.objectStore('events')
      store.add(data).onsuccess = (event) => {
        // console.log('DB stored', data?.name)
      }
    } else {
      this.#logWaitList.push({ side, tab, ts, type, args })
    }
  }

  async #storeTabInfo (tab, userId, title, time) {
    if (isWindow && tab) {
      if (this.#isOpened) {
        const info = await this.getTabInfo(tab)
        const tr = this.#db.transaction(['tabs'], 'readwrite')
        const store = tr.objectStore('tabs')

        if (typeof info === 'undefined') {
          store.add({
            tab,
            user_id: userId,
            title,
            open_at: time,
            last_active_at: time
          }).onsuccess = (event) => {
            // console.log('DB stored', data?.name)
          }
        } else {
          const index = store.index('tab')
          const data = {
            ...info,
            user_id: userId,
            title,
            last_active_at: time
          }
          index.getKey(IDBKeyRange.only(tab)).onsuccess = (e) => {
            store.put(data, e.target.result).onsuccess = (event) => {
              // console.log('DB updated:', data)
            }
          }
        }
      } else {
        this.#tabWaitList.push({ tab, userId, title, time })
      }
    }
  }

  async clearLog () {
    if (this.#isOpened && isWindow) {
      const tr = this.#db.transaction(['tabs', 'events'], 'readwrite')
      const tabs = tr.objectStore('tabs')
      const events = tr.objectStore('events')
      return await Promise.all([tabs.clear(), events.clear()])
    }
  }

  async clearTabLog (tab) {
    if (this.#isOpened && isWindow) {
      const tr = this.#db.transaction(['events'], 'readwrite')
      const events = tr.objectStore('events')
      const index = events.index('tab')

      return await new Promise((resolve, reject) => {
        const promises = []
        const res = index.openCursor(IDBKeyRange.only(tab))
        res.onsuccess = (event) => {
          const cursor = event.target.result
          if (cursor) {
            promises.push(new Promise((resolve) => {
              resolve(cursor.delete())
            }))
            cursor.continue()
          } else {
            Promise.all(promises)
              .then(r => resolve(r))
              .catch(e => reject(e))
          }
        }
        res.onerror = e => reject(e)
      })
        .then((r) => {
          return r
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  log (side, tab, ts, ...args) {
    this.#store(side, tab, ts, 'log', args)
  }

  info (side, tab, ts, ...args) {
    this.#store(side, tab, ts, 'info', args)
  }

  warn (side, tab, ts, ...args) {
    this.#store(side, tab, ts, 'warn', args)
  }

  error (side, tab, ts, ...args) {
    this.#store(side, tab, ts, 'error', args)
  }

  getLog (props) {
    return new Promise((resolve, reject) => {
      if (this.#isOpened) {
        const tr = this.#db.transaction(['events'], 'readwrite')
        const store = tr.objectStore('events')
        if (props?.tabId) {
          const pTab = new Promise((resolve, reject) => {
            const list = []
            const index = store.index('tab')
            const res = index.openCursor(IDBKeyRange.only(props.tabId))
            res.onsuccess = (event) => {
              const cursor = event.target.result
              if (cursor) {
                list.push(event.target.result?.value)
                cursor.continue()
              } else {
                resolve(list)
              }
            }
            res.onerror = e => reject(e)
          })
          const pBg = new Promise((resolve, reject) => {
            const list = []
            const index2 = store.index('side_tab_time')
            const query = IDBKeyRange.bound(['wkr', '', (props?.openAt || 0)], ['wkr', '', (props?.lastActiveAt || new Date())])
            const res = index2.openCursor(query)
            res.onsuccess = (event) => {
              const cursor = event.target.result
              if (cursor) {
                list.push(event.target.result?.value)
                cursor.continue()
              } else {
                resolve(list)
              }
            }
            res.onerror = e => reject(e)
          })
          Promise.all([pTab, pBg])
            .then((r) => {
              // console.log('lg', r, Object.keys(props))
              resolve([...r[0], ...r[1]])
            })
            .catch(e => reject(e))
        } else {
          store.getAll().onsuccess = (event) => {
            // console.log(`Got all logs: ${event.target.result}`)
            resolve(event.target.result)
          }
        }
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Not connected yet...')
      }
    })
  }

  async getTabInfo (tabKey) {
    if (this.#isOpened && tabKey) {
      const tr = this.#db.transaction(['tabs'], 'readwrite')
      const store = tr.objectStore('tabs')
      const index = store.index('tab')
      const data = await new Promise((resolve, reject) => {
        const res = index.get(IDBKeyRange.only(tabKey))
        res.onsuccess = e => resolve(e.target.result)
        res.onerror = e => reject(e)
      })
        .then(r => r)
      return data
    }
  }

  async getTabKeys () {
    const res = []
    if (this.#isOpened) {
      const keys = await (new Promise((resolve, reject) => {
        const tr = this.#db.transaction(['tabs'], 'readwrite')
        const store = tr.objectStore('tabs')
        const index = store.index('tab')
        const keys = []
        index.openKeyCursor(null, 'nextunique').onsuccess = (event) => {
          const cursor = event.target.result
          if (cursor) {
            keys.push(cursor.key)
            cursor.continue()
          } else {
            resolve(keys.filter(i => i !== ''))
          }
        }
      })).then(r => r)
      for (const key of keys) {
        const data = await this.getTabSummary(key)
        const tabData = await this.getTabInfo(key)
        res.push({ key, ...data, ...(tabData || {}) })
      }
    }
    return res
  }

  async getTabSummary (tabKey) {
    if (this.#isOpened) {
      const tr = this.#db.transaction(['events'], 'readwrite')
      const store = tr.objectStore('events')
      const index = store.index('tab_time')
      const countPromise = new Promise((resolve, reject) => {
        const req = index.count(IDBKeyRange.bound([tabKey, 0], [tabKey, new Date()]))
        req.onsuccess = (e) => {
          resolve(e.target.result)
        }
        req.onerror = e => reject(e)
      })
      const data = await Promise.all([countPromise])
        .then((res) => {
          return res
        })

      return {
        count: data[0],
        open_at: data[1],
        last_active_at: data[2]
      }
    }
  }
}

export default DbLog
