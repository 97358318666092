import nav from '~/nav'
import { isWindow } from '@/mixins/helpers'

export default function ({ $gates, $auth, $config, route, redirect, store }) {
  const appNav = store.state?.nav?.nav?.length
    ? store.state.nav.nav
    : nav
  if (
    route.path === '/404' ||
    route.path === '/profile' ||
    route.path === '/login'
  ) {
    return
  }
  if (isWindow && !$config.appDebug && route.path.match(/\/debug/)) {
    redirect('/')
  }
  const userRoles = $auth?.user?.roles || []
  let navItem
  const roleNav = appNav
    .filter((i) => {
      let exists = false
      userRoles.forEach((role) => {
        if (i.roles.split('|').includes(role)) {
          exists = true
        }
      })
      return exists
    })
  roleNav
    .forEach((i) => {
      if (i.childs && i.childs.length) {
        return i.childs.forEach((j) => {
          const ms = route.path.match(new RegExp(`^${j.path}`))
          // console.log('GATES ms', j.path, ms)
          if (ms) {
            navItem = j
          }
        })
      }
      const m = route.path.match(new RegExp(`^${i.path}`))
      if (m) {
        navItem = i
      }
    })
  const roles = (navItem && navItem.roles) || ''
  const acceptPrivate = ($auth.user?.is_private_user && roles.split('|').includes('private')) || false
  const acceptRole = $gates.hasAnyRole(roles)
  const acceptEnabled = !!navItem?.isEnabled
  // If the user role access denied
  if (($auth.user && !acceptPrivate && !(!acceptPrivate && acceptRole)) || !acceptEnabled) {
    // console.log('MIDDLEWARE', $auth.user && !acceptPrivate && !(!acceptPrivate && acceptRole))
    const defaultItem = roleNav.find(i => i.isDefault)
    return defaultItem && defaultItem.isEnabled
      ? redirect(defaultItem.path)
      : (isWindow ? window?.location.replace('/') : redirect('/'))
  }
  // return false
}
